.services-section {
  background-color: black;
  color: white;
  padding: 50px 20px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  border: 5px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}

.services-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
  align-items: flex-start;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: inset 0 0 10px #000;
}

.services-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  border-bottom: 2px solid gray;
  padding-bottom: 10px;
  color: white; /* Updated to white */
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.service-item {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid gray;
  flex: 1 1 calc(33% - 40px);
  box-sizing: border-box;
  max-width: calc(33% - 40px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
  text-decoration: none; /* Remove underline for all service items */
}

.service-item h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px #000;
}

.service-item p {
  font-size: 1.2rem;
  line-height: 1.4;
}

.service-item:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.service-item:before, .service-item:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid gray;
  top: -10px;
  left: -10px;
  transition: transform 0.3s ease;
  z-index: -1;
}

.service-item:after {
  top: 10px;
  left: 10px;
}

.service-item:hover:before, .service-item:hover:after {
  transform: scale(1.1);
}

/* Remove underline specifically for Link components */
.service-item a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .service-item {
    flex: 1 1 calc(50% - 40px);
    max-width: calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .service-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
