.partners-container {
    background-color: white;
    color: black;
    padding: 50px 20px;
    text-align: left;
    font-family: 'Courier New', Courier, monospace;
    border: 5px solid gray;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 50px auto;
  }
  
  .partners-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    border-bottom: 2px solid gray;
    padding-bottom: 10px;
    color: black;
    animation: fadeIn 1s ease-in-out;
  }
  
  .partners-icon-gray {
    color: gray;
    margin-right: 10px;
  }
  
  .partners-intro {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: black;
    animation: fadeIn 1s ease-in-out;
  }
  
  .partners-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .partners-card {
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .partners-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: white;
  }
  
  .partners-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .partners-card p {
    font-size: 1rem;
    color: white; /* White text */
  }
  
  .partners-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .partners-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: black;
    border: 2px solid black;
    background: transparent;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    transition: background 0.3s, color 0.3s;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .partners-button:hover {
    background-color: rgb(61, 59, 59);
    color: white;
  }
  
  .partners-form {
    margin-top: 50px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .partners-form-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: black;
    text-align: center;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-size: 1.2rem;
  }
  
  .form-group input,
  .form-group select {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: rgba(10, 11, 12, 0.8); /* Dark background */
    color: white; /* White text */
  }
  
  .form-group select {
    background-color: rgba(10, 11, 12, 0.8); /* Ensure dropdown has a consistent background */
  }
  
  .form-group select option {
    color: white; /* Ensure options are visible */
  }
  
  .button-container {
    display: flex;
    justify-content: center; /* Center the submit button */
  }
  
  .partners-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: black;
    border: 2px solid black;
    background: transparent;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    transition: background 0.3s, color 0.3s;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .partners-button:hover {
    background-color: rgb(61, 59, 59);
    color: white;
  }
  
  .privacy-text {
    font-size: 0.9rem;
    color: gray;
    margin-top: 10px;
    text-align: center;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  