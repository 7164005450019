.managed-services-page {
    font-family: 'Courier New', Courier, monospace;
    color: #b31a1a;
    font-weight: bold;
    background-color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .monitoring-setup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .monitoring-screen {
    width: 90vw;
    max-width: 700px;
    height: 60vh;
    max-height: 500px;
    background-color: #111;
    border: 20px solid #333;
    border-radius: 10px;
    box-shadow: 0 0 30px 10px rgba(255, 255, 255, 0.5);
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
  }
  
  .monitoring-screen.dimmed {
    background-color: rgba(17, 17, 17, 0.7);
  }
  
  .log-screen {
    background-color: #1e1e1e;
    padding: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 10px;
  }
  
  .monitoring-log {
    margin: 0;
    line-height: 1.5;
    animation: appear 0.5s ease-in-out;
  }
  
  @keyframes appear {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .reveal-content {
    margin-top: 20px;
    text-align: center;
    position: absolute; /* Make the popup absolutely positioned */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the popup */
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8); /* Slightly darken background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Add a slight shadow */
  }
  
  .secure-content-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
  }
  
  .secure-content-text {
    font-size: 16px;
    color: turquoise;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .shield-icon {
    margin-right: 10px;
  }
  
  .monitoring-base {
    width: 90vw;
    max-width: 700px;
    height: 40px;
    background-color: #333;
    border-radius: 0 0 10px 10px;
    margin-top: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: relative;
  }
  
  .floppy-drive {
    width: 220px;
    height: 12px;
    background-color: #444;
    border: 2px solid #555;
    border-radius: 12px;
  }
  
  .lights {
    display: flex;
    gap: 10px;
  }
  
  .light {
    width: 15px;
    height: 15px;
    background-color: #333;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .light.red {
    animation: blink-red 1s infinite;
  }
  
  .light.green {
    animation: blink-green 1s infinite;
  }
  
  .light.yellow {
    animation: blink-yellow 1s infinite;
  }
  
  @keyframes blink-red {
    0%, 100% { background-color: #800; }
    50% { background-color: #f00; }
  }
  
  @keyframes blink-green {
    0%, 100% { background-color: #080; }
    50% { background-color: #0f0; }
  }
  
  @keyframes blink-yellow {
    0%, 100% { background-color: #880; }
    50% { background-color: #ff0; }
  }
  
  .schedule-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    color: white; /* White text color */
    border: 2px solid white; /* White border */
    background: transparent;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    transition: background 0.3s, color 0.3s;
    border-radius: 25px; /* Makes the button rounded */
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace; /* Apply font to button */
  }
  
  .schedule-button span {
    margin-left: 10px;
    transition: margin-left 0.3s;
  }
  
  .schedule-button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .schedule-button:hover span {
    margin-left: 15px;
  }
  
  /* Media Queries for Mobile View */
  @media (max-width: 768px) {
    .monitoring-screen {
      width: 80vw;
      height: 50vh;
    }
  
    .monitoring-base {
      width: 80vw;
      height: 30px;
    }
  
    .floppy-drive {
      width: 80px;
      height: 15px;
    }
  
    .secure-content-title {
      font-size: 20px;
    }
  
    .secure-content-text {
      font-size: 14px;
    }
  
    .schedule-button {
      padding: 8px 16px;
      font-size: 0.875rem;
    }
  
    .reveal-content {
      padding: 15px;
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .managed-services-page {
      padding-top: 20px;
    }
  
    .monitoring-screen {
      width: 90vw;
      height: 40vh;
    }
  
    .monitoring-base {
      width: 90vw;
      height: 25px;
    }
  
    .floppy-drive {
      width: 120px;
      height: 12px;
    }
  
    .secure-content-title {
      font-size: 18px;
    }
  
    .secure-content-text {
      font-size: 14px;
      font-weight: bold;
    }
  
    .schedule-button {
      padding: 6px 12px;
      font-size: 0.75rem;
    }
  
    .reveal-content {
      margin-top: 60px;
      font-size: 16px;
    }
  }
  
  /* Additional Sections Styling */
  .additional-sections {
    width: 90vw;
    max-width: 1200px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .section-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center; /* Center align the section titles */
    width: 100%;
    border-bottom: 2px solid gray;
    padding-bottom: 10px;
    color: white;
  }
  
  .section-icon {
    font-size: 2rem;
    color: turquoise;
  }
  
  .threat-detection-section, 
  .incident-response-section, 
  .compliance-management-section {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .threat-detection-section p,
  .incident-response-section p,
  .compliance-management-section p,
  .threat-detection-section ul,
  .incident-response-section ul,
  .compliance-management-section ul {
    font-size: 1.5rem;
    line-height: 1.6;
    color: #ddd;
  }
  
  .threat-detection-section ul,
  .incident-response-section ul,
  .compliance-management-section ul {
    list-style: none;
    padding-left: 20px;
  }
  
  .threat-detection-section li,
  .incident-response-section li,
  .compliance-management-section li {
    margin-bottom: 10px;
    position: relative;
  }
  
  .threat-detection-section li:before,
  .incident-response-section li:before,
  .compliance-management-section li:before {
    content: '•';
    position: absolute;
    left: -15px;
    color: turquoise;
  }
  
  /* Schedule Consultation Section */
  .schedule-consultation-section {
    background-color: white;
    color: black;
    padding: 50px 20px;
    text-align: left;
    font-family: 'Courier New', Courier, monospace;
    border: 5px solid gray;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    max-width: 800px;
    margin: 50px auto; /* Added margin for top and bottom buffer */
  }
  
  .consultation-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .form-group label {
    font-size: 1.2rem;
  }
  
  .form-control {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: rgba(10, 11, 12, 0.8); /* Dark background */
    color: white; /* White text */
    height: 44px; /* Consistent height for all input elements */
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }
  
  .privacy-text {
    font-size: 0.9rem;
    color: gray;
    margin-top: 10px;
  }
  
  .transparent-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    color: black;
    border: 2px solid black;
    background: transparent;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    transition: background 0.3s, color 0.3s;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .transparent-button span {
    margin-left: 10px;
    transition: margin-left 0.3s;
  }
  
  .transparent-button:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .transparent-button:hover span {
    margin-left: 15px;
  }
  
  .confirmation-message {
    font-size: 1.5rem;
    color: green;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
    }
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    height: 44px; /* Ensure consistent height */
    background-color: rgba(10, 11, 12, 0.8); /* Dark background */
    color: white; /* White text */
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }
  
  .no-underline {
    color: #40e0d0; /* Turquoise color */
    text-decoration: none;
  }
  
  .no-underline:hover {
    color: #ff4500; /* Change to red on hover */
  }
  