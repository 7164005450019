.capabilities-page {
    position: relative;
    background-color: white;
    font-family: 'Courier New', Courier, monospace;
    padding: 20px;
    max-width: 1200px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.monitor {
    background-color: black;
    color: white;
    border: 5px solid gray;
    border-radius: 15px;
    padding: 20px;
    width: 98%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.monitor.off {
    background-color: black;
    color: black;
}

.title {
    font-size: 3rem;
    margin-bottom: 20px;
    text-align: center;
}

.capabilities-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
    overflow: auto;
    height: 100%;
}

.capability-tile {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    width: calc(33% - 20px);
    min-width: 200px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.capability-tile:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}

.capability-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.capability-icon {
    margin-right: 10px;
}

.capability-list {
    list-style: none;
    padding: 0;
    text-align: left;
    font-size: 1rem;
}

.capability-item {
    padding: 5px 0;
    border-bottom: 1px solid #444;
    transition: color 0.3s, padding-left 0.3s;
}

.capability-item:hover {
    color: turquoise;
    padding-left: 10px;
}

.monitor-off {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.logocapabilities {
    font-size: 38px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin-bottom: 20px;
}

.monitor-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    color: white; /* White text color */
    border: 2px solid white; /* White border */
    background: transparent;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    transition: background 0.3s, color 0.3s;
    border-radius: 25px; /* Makes the button rounded */
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace; /* Apply font to button */
}

.monitor-button span {
    margin-left: 10px;
    transition: margin-left 0.3s;
}

.monitor-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.monitor-button:hover span {
    margin-left: 15px;
}

.power-button {
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 1.5rem;
    transition: background-color 0.3s;
}

.power-button.on {
    background-color: green;
}

.power-button.off {
    background-color: black;
}

.power-button:hover {
    background-color: green;
}

.monitor-stand {
    width: 300px;
    height: 40px;
    background-color: black;
    border-radius: 0 0 10px 10px;
    margin-top: -10px; /* Adjust to position under monitor */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.monitor-stand::before,
.monitor-stand::after {
    content: '';
    width: 30px;
    height: 20px;
    background-color: black;
    border-radius: 0 0 10px 10px;
    position: absolute;
    bottom: -20px;
}

.monitor-stand::before {
    left: 60px;
}

.monitor-stand::after {
    right: 60px;
}

.monitor-stand::after, .monitor-stand::before {
    content: '';
    position: absolute;
    height: 10px;
    background-color: black;
    bottom: 0;
    width: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .capability-tile {
        width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .monitor {
        width: 90%;
        border: 5px solid gray;
        min-height: 400px; /* Make the screen smaller */
    }
    .monitor-stand {
        display: none; /* Hide the stand and feet */
    }
    .power-button {
        bottom: 10px; /* Adjust the position of the power button */
        right: 10px;
    }
    .capability-tile {
        width: 100%;
    }
}
