.consultation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.consultation-modal-container {
  background-color: white;
  color: black;
  padding: 30px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  border: 5px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 800px;
  width: 90%;
  margin: 20px; /* Add margin for buffer space */
}

.consultation-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.consultation-modal-form-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: gray; /* Optional: Add color to differentiate */
}

.consultation-modal-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.consultation-modal-form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.consultation-modal-form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.consultation-modal-form-group label {
  font-size: 1.2rem;
}

.consultation-modal-form-control {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: rgba(10, 11, 12, 0.8); /* Dark background */
  color: white; /* White text */
  height: 44px; /* Consistent height for all input elements */
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.consultation-modal-privacy-text {
  font-size: 0.9rem;
  color: gray;
  margin-top: 10px;
}

.consultation-modal-transparent-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: black;
  border: 2px solid black;
  background: transparent;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px;
  cursor: pointer;
}

.consultation-modal-transparent-button span {
  margin-left: 10px;
  transition: margin-left 0.3s;
}

.consultation-modal-transparent-button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.consultation-modal-transparent-button:hover span {
  margin-left: 15px;
}

.consultation-modal-confirmation-message {
  font-size: 1.5rem;
  color: green;
  text-align: center;
}

@media (max-width: 768px) {
  .consultation-modal-container {
    width: 80%;
    margin: 10px; /* Add margin for buffer space */
  }

  .consultation-modal-form-row {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .consultation-modal-container {
    width: 95%;
    padding: 20px;
    max-height: 90%; /* Limit the height of the modal */
    overflow-y: auto; /* Enable scrolling within the modal */
  }

  .consultation-modal-form-title {
    font-size: 1.5rem;
  }

  .consultation-modal-form-group label {
    font-size: 1rem;
  }

  .consultation-modal-form-control {
    height: 40px;
    font-size: 0.9rem;
  }

  .consultation-modal-privacy-text {
    font-size: 0.8rem;
  }

  .consultation-modal-transparent-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

.consultation-modal-react-datepicker-wrapper {
  width: 100%;
}

.consultation-modal-react-datepicker__input-container input {
  width: 100%;
  height: 44px; /* Ensure consistent height */
  background-color: rgba(10, 11, 12, 0.8); /* Dark background */
  color: white; /* White text */
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.consultation-modal-no-underline {
  color: #40e0d0; /* Turquoise color */
  text-decoration: none;
}

.consultation-modal-no-underline:hover {
  color: #ff4500; /* Change to red on hover */
}
