.consultation-section {
  background-color: white;
  color: black;
  padding: 50px 20px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  border: 5px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  max-width: 800px;
  margin: 50px auto; /* Added margin for top and bottom buffer */
}

.section-title-consultation {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  border-bottom: 2px solid gray;
  padding-bottom: 10px;
  color: black; /* Set the color to black */
}

.consultation-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-size: 1.2rem;
}

.form-control {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: rgba(10, 11, 12, 0.8); /* Dark background */
  color: white; /* White text */
  height: 44px; /* Consistent height for all input elements */
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.privacy-text {
  font-size: 0.9rem;
  color: gray;
  margin-top: 10px;
}

.transparent-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: black;
  border: 2px solid black;
  background: transparent;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px;
  cursor: pointer;
}

.transparent-button span {
  margin-left: 10px;
  transition: margin-left 0.3s;
}

.transparent-button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.transparent-button:hover span {
  margin-left: 15px;
}

.confirmation-message {
  font-size: 1.5rem;
  color: green;
  text-align: center;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 44px; /* Ensure consistent height */
  background-color: rgba(10, 11, 12, 0.8); /* Dark background */
  color: white; /* White text */
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.no-underline {
  color: #40e0d0; /* Turquoise color */
  text-decoration: none;
}

.no-underline:hover {
  color: #ff4500; /* Change to red on hover */
}
