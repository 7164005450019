.terms-container {
    background-color: white;
    color: black;
    padding: 50px 20px;
    text-align: left;
    font-family: 'Courier New', Courier, monospace;
    border: 5px solid gray;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 50px auto;
  }
  
  .terms-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    border-bottom: 2px solid gray;
    padding-bottom: 10px;
    color: black;
  }
  
  .terms-content {
    animation: fadeIn 1s ease-in-out;
  }
  
  .terms-content h3 {
    font-size: 1.8rem;
    margin-top: 20px;
    color: black;
  }
  
  .terms-content p {
    font-size: 1.2rem;
    margin-top: 10px;
    color: black;
  }
  
  .terms-content ul {
    list-style-type: disc;
    margin-left: 20px;
    color: black;
  }
  
  .terms-content ul li {
    font-size: 1.2rem;
    margin-top: 5px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  