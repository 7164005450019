.cybersecurity-section {
  background-color: black;
  color: white;
  padding: 50px 20px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  border: 5px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}

.cybersecurity-container {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
  align-items: flex-start;
}

.cybersecurity-left {
  flex: 1;
  padding: 20px;
  font-size: 1.5rem;
}

.cybersecurity-left h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.cybersecurity-left p {
  font-size: 1.5rem;
  line-height: 1.6;
}

.cybersecurity-right {
  flex: 1;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.cybersecurity-section-item {
  margin-bottom: 20px;
}

.cybersecurity-section-item h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.cybersecurity-section-item p {
  font-size: 1.2rem;
  line-height: 1.4;
}

.cybersecurity-icon {
  color: #4e4c4b; /* Use the same turquoise color */
  margin-top: 10px;
}

.cybersecurity-hero-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: white;
  border: 2px solid white;
  background: transparent;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px; /* Makes the button rounded */
  cursor: pointer;
}

.cybersecurity-hero-button span {
  margin-left: 10px;
  transition: margin-left 0.3s;
}

.cybersecurity-hero-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.cybersecurity-hero-button:hover span {
  margin-left: 15px;
}

.cybersecurity-centered-button {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .cybersecurity-container {
    flex-direction: column;
  }

  .cybersecurity-left, 
  .cybersecurity-right {
    width: 96%;
    padding: 10px;
  }

  .cybersecurity-section-item h3 {
    font-size: 1.8rem;
  }

  .cybersecurity-section-item p {
    font-size: 1.2rem;
  }
}
