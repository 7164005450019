.incident-response-page {
  font-family: 'Courier New', Courier, monospace;
  color: #640808;
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.incident-response-impact-calculator-section {
  background-color: #111;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  max-width: 800px;
  width: 100%;
  margin-bottom: 40px;
}

.incident-response-section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: white;
  position: relative;
}

.incident-response-section-title::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: gray;
  margin-top: 10px;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.incident-response-calculator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.incident-response-calculator-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.incident-response-input-group {
  margin-bottom: 15px;
}

.incident-response-input-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.incident-response-input-group input,
.incident-response-input-group select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid gray;
  background-color: #333;
  color: white;
}

.incident-response-calculate-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #b31a1a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.incident-response-calculate-button:hover {
  background-color: #e50e0e;
}

.incident-response-calculator-results {
  margin-top: 20px;
  text-align: center;
}

.incident-response-calculator-results h3 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.incident-response-calculator-results p {
  font-size: 1.5rem;
  margin: 10px 0;
}

.incident-response-savings-highlight {
  color: #40e0d0;
  font-weight: bold;
}

.incident-response-section-icon {
  font-size: 2rem;
  color: turquoise;
}

.incident-response-additional-sections {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.incident-response-rapid-response-section,
.incident-response-forensic-analysis-section,
.incident-response-cost-management-section,
.incident-response-why-pick-patheye-section {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 40px; /* Added margin between sections */
}

.incident-response-rapid-response-section p,
.incident-response-forensic-analysis-section p,
.incident-response-cost-management-section p,
.incident-response-why-pick-patheye-section p,
.incident-response-rapid-response-section ul,
.incident-response-forensic-analysis-section ul,
.incident-response-cost-management-section ul {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #ddd;
}

.incident-response-rapid-response-section ul,
.incident-response-forensic-analysis-section ul,
.incident-response-cost-management-section ul {
  list-style: none;
  padding-left: 20px;
}

.incident-response-rapid-response-section li,
.incident-response-forensic-analysis-section li,
.incident-response-cost-management-section li {
  margin-bottom: 10px;
  position: relative;
}

.incident-response-rapid-response-section li:before,
.incident-response-forensic-analysis-section li:before,
.incident-response-cost-management-section li:before {
  content: '•';
  position: absolute;
  left: -15px;
  color: turquoise;
}
