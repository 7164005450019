/* General Terminal Page Styling */
.pentesting-terminal-page {
  font-family: 'Courier New', Courier, monospace;
  color: #b31a1a;
  font-weight: bold;
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terminal-setup {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.terminal-monitor {
  width: 90vw;
  max-width: 700px;
  height: 60vh;
  max-height: 500px;
  background-color: #111;
  border: 20px solid #333;
  border-radius: 10px;
  box-shadow: 0 0 30px 10px rgba(255, 255, 255, 0.5);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.terminal-monitor.dimmed {
  background-color: rgba(17, 17, 17, 0.7);
}

.terminal-screen {
  background-color: #1e1e1e;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-radius: 10px;
}

.terminal-log {
  margin: 0;
  line-height: 1.5;
  animation: appear 0.5s ease-in-out;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.reveal-content {
  margin-top: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.secure-content-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.secure-content-text {
  font-size: 16px;
  color: turquoise;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warning-icon {
  margin-right: 10px;
}

.terminal-base {
  width: 90vw;
  max-width: 700px;
  height: 40px;
  background-color: #333;
  border-radius: 0 0 10px 10px;
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
}

.floppy-drive {
  width: 220px;
  height: 12px;
  background-color: #444;
  border: 2px solid #555;
  border-radius: 12px;
}

.lights {
  display: flex;
  gap: 10px;
}

.light {
  width: 15px;
  height: 15px;
  background-color: #333;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.light.red {
  animation: blink-red 1s infinite;
}

.light.green {
  animation: blink-green 1s infinite;
}

.light.yellow {
  animation: blink-yellow 1s infinite;
}

@keyframes blink-red {
  0%,
  100% {
    background-color: #800;
  }
  50% {
    background-color: #f00;
  }
}

@keyframes blink-green {
  0%,
  100% {
    background-color: #080;
  }
  50% {
    background-color: #0f0;
  }
}

@keyframes blink-yellow {
  0%,
  100% {
    background-color: #880;
  }
  50% {
    background-color: #ff0;
  }
}

.pentesthero-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: white;
  border: 2px solid white;
  background: transparent;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
}

.pentesthero-button span {
  margin-left: 10px;
  transition: margin-left 0.3s;
}

.pentesthero-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.pentesthero-button:hover span {
  margin-left: 15px;
}

/* Schedule Consultation Section */
.schedule-consultation-section {
  background-color: white;
  color: black;
  padding: 50px 20px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  border: 5px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  max-width: 800px;
  margin: 50px auto;
}

.schedule-consultation-section-title {
  color: black;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid gray;
  padding-bottom: 10px;
}

.schedule-consultation-section-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.schedule-consultation-section-form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.schedule-consultation-section-form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.schedule-consultation-section-form-control {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: rgba(10, 11, 12, 0.8);
  color: white;
  height: 44px;
  width: 100%;
  box-sizing: border-box;
}

.schedule-consultation-section-privacy-text {
  font-size: 0.9rem;
  color: gray;
  margin-top: 10px;
}

.schedule-consultation-section-submit-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: black;
  border: 2px solid black;
  background: transparent;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px;
  cursor: pointer;
}

.schedule-consultation-section-submit-button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.schedule-consultation-section-no-underline {
  color: #40e0d0;
  text-decoration: none;
}

.schedule-consultation-section-no-underline:hover {
  color: #ff4500;
}

.schedule-consultation-section-confirmation-message {
  font-size: 1.5rem;
  color: green;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .terminal-monitor {
    width: 80vw;
    height: 50vh;
  }

  .schedule-consultation-section-form-row {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .pentesting-terminal-page {
    padding-top: 20px;
  }

  .terminal-monitor {
    width: 90vw;
    height: 40vh;
  }
}
