/* Add this at the top of your CSS file */
body {
  font-family: 'Courier New', Courier, monospace;
}

.hero {
  position: relative;
  width: 100%;
  height: calc(100vh - 70px); /* Full viewport height minus header height */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin: 0; /* Ensure no margin on the hero section */
  padding: 0; /* Ensure no padding on the hero section */
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Reduce opacity of black overlay */
  z-index: 0;
}

.hero-content {
  z-index: 1;
  max-width: 60%;
  margin: auto;
  padding: 60px;
  background: rgba(0, 0, 0, 0.805); /* Add a more transparent background to the text area */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
  text-align: center;
  font-family: 'Courier New', Courier, monospace; /* Apply font to hero content */
}

.hero-content h1 {
  font-size: 2.5rem; /* Adjust font size */
  margin-bottom: 10px; /* Reduce bottom margin */
  color: white; /* White text color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow */
}

.hero-content p {
  font-size: 1.2rem; /* Adjust font size */
  margin-bottom: 20px; /* Reduce bottom margin */
  color: white; /* White text color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow */
}

.hero-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: white; /* White text color */
  border: 2px solid white; /* White border */
  background: transparent;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px; /* Makes the button rounded */
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace; /* Apply font to button */
}

.hero-button span {
  margin-left: 10px;
  transition: margin-left 0.3s;
}

.hero-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.hero-button:hover span {
  margin-left: 15px;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }
}
