.privacy-policy-page-container {
  font-family: 'Courier New', Courier, monospace;
  color: black;
  background-color: #f9f9f9;
  padding: 50px;
}

.privacy-policy-page-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy-policy-page-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
}

.privacy-policy-page-icon {
  margin-left: 10px;
  font-size: 1.5rem;
  color: #333;
}

.privacy-policy-page-subtitle {
  font-size: 1.8rem;
  margin-top: 20px;
  color: #444;
}

.privacy-policy-page-list {
  list-style-type: disc;
  margin-left: 20px;
  color: #555;
}

.privacy-policy-page-list li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .privacy-policy-page-content {
    padding: 20px;
  }

  .privacy-policy-page-title {
    font-size: 2rem;
  }

  .privacy-policy-page-subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .privacy-policy-page-content {
    padding: 15px;
  }

  .privacy-policy-page-title {
    font-size: 1.8rem;
  }

  .privacy-policy-page-subtitle {
    font-size: 1.2rem;
  }
}
