.footer {
  background-color: #0a0b0c;
  color: #ffffff;
  text-align: center;
  padding: 20px;
  border-top: 3px solid #40e0d0;
  font-family: 'Courier New', Courier, monospace;
  position: relative;
  z-index: 10;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 20px; /* Added gap for spacing between columns */
}

.footer-column {
  flex: 1;
  min-width: 220px; /* Slightly increased min-width for better balance */
  margin: 10px;
  text-align: left;
}

.footer-column h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
}

.footer-column ul li a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer-column ul li a:hover {
  color: #ff4500;
}

.newsletter-column p a {
  color: #40e0d0;
  font-weight: bold;
  text-decoration: none;
}

.newsletter-column p a:hover {
  color: #ff4500;
}

.newsletter-column h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.newsletter-column p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.newsletter-form {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.newsletter-form input {
  padding: 10px;
  font-size: 0.9rem;
  border: 2px solid #ffffff;
  border-radius: 5px;
  background: rgba(10, 11, 12, 0.8);
  color: #ffffff;
  flex: 1;
}

.newsletter-form input::placeholder {
  color: #ffffff;
}

.transparent-button2 {
  display: inline-block;
  padding: 5px 10px;
  color: #ffffff;
  border: 2px solid #ffffff;
  background: #000000;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.9rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px;
  cursor: pointer;
}

.transparent-button2 span {
  margin-left: 10px;
  transition: margin-left 0.3s;
}

.transparent-button2:hover {
  background: #2c2b2b;
}

.transparent-button2:hover span {
  margin-left: 15px;
}

.contact, .copyright {
  margin: 10px 0;
  font-size: 1.2rem;
  text-shadow: 0 0 5px rgba(64, 224, 208, 0.7);
}

.contact a {
  color: #40e0d0;
  text-decoration: none;
}

.contact a:hover {
  color: #ff4500;
}

.confirmation-message {
  margin-top: 10px;
  color: #40e0d0;
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 80%;
  height: 5px;
  background: linear-gradient(to right, #ff4500, #40e0d0);
  transform: translateX(-50%);
}

.footer::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 60%;
  height: 2px;
  background: linear-gradient(to right, #40e0d0, #ff4500);
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .footer {
    padding: 10px;
  }

  .footer-content {
    padding: 10px;
  }

  .footer-columns {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    text-align: center;
  }

  .contact, .copyright {
    font-size: 1rem;
  }

  .footer::before, .footer::after {
    width: 90%;
  }

  .news-confirmation-message {
    color: green;
    font-size: 0.9rem;
    margin-top: 10px;
  }
}
