/* Center the flyer specifically for the /flyer page */
.flyer_flyer {
    width: 8.5in;
    max-width: 100%;
    height: 25in;
    background-color: #000;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    display: flex;
    flex-direction: column;
    padding: 40px;
    margin: 40px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    justify-content: center; /* Ensure centering */
}

/* Page 1 */
.flyer_flyer-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

/* Header */
.flyer_flyer-header {
    text-align: center;
}

.flyer_flyer-logo {
    font-size: 68px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-decoration: none;
}

/* Subtitle */
.flyer_flyer-subtitle {
    font-size: 1.5rem;
    color: #ddd;
    text-align: center;
}

/* Offer */
.flyer_flyer-offer h2 {
    font-size: 1.8rem;
    color: white;
    text-align: center;
}

.flyer_flyer-discount {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 10px;
}

.flyer_highlight {
    color: #40e0d0;
}

/* Guarantee */
.flyer_flyer-guarantee {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    font-size: 1.5rem;
}

.flyer_flyer-icon_large {
    font-size: 2.5rem;
    margin-right: 10px;
}

.flyer_flyer-guarantee-text {
    font-size: 1.8rem;
    color: #40e0d0;
}

/* Contact Info on Page 1 */
.flyer_flyer-contact-info {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 10px;
}

.flyer_flyer-icon {
    margin-right: 5px;
}

/* Coupon Section */
.flyer_flyer-coupon {
    border: 2px dashed #40e0d0;
    padding: 15px;
    text-align: center;
    margin: 20px 0;
    background-color: #101010; /* Slight background difference for coupons */
    transition: transform 0.2s ease; /* Hover effect */
}

.flyer_flyer-coupon:hover {
    transform: scale(1.05); /* Scale slightly on hover to make the coupon pop */
}

.flyer_flyer-coupon-scissors {
    margin-right: 10px;
}

.flyer_flyer-coupon-text {
    font-size: 1.5rem;
    color: white;
}

/* Add visual separation between the two coupons */
.flyer_flyer-coupon:nth-child(3) {
    margin-bottom: 30px; /* Extra margin between first and second coupon */
}

/* Cleveland Skyline */
.flyer_flyer-skyline {
    width: 100%;
    height: 400px;
    background: url('/public/216.webp') no-repeat top;
    background-size: cover;
    opacity: 0.3;
}

/* QR Code Section */
.flyer_flyer-qrcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.flyer_flyer-qrcode-image {
    width: 125px;
    height: 125px;
}

/* Page 2 - Larger Font Sizes */
.flyer_flyer-why-choose-us h2, .flyer_flyer-service-list h2 {
    font-size: 3rem;
    margin-bottom: 15px;
    color: #40e0d0;
    text-align: center;
}

.flyer_flyer-why-choose-us p, .flyer_flyer-service-list ul {
    text-align: center;
    font-size: 1.8rem;
}

.flyer_flyer-service-list ul {
    list-style: none;
    padding: 0;
}

.flyer_flyer-service-list li {
    margin: 15px 0;
    font-size: 1.4rem;
    text-align: center;
}

/* Footer */
.flyer_flyer-footer {
    text-align: center;
    font-size: 1.2rem;
    color: #40e0d0;
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .flyer_flyer {
        padding: 20px;
        margin: 20px 0;
        height: auto; 
    }

    .flyer_flyer-logo {
        font-size: 36px;
    }

    .flyer_flyer-subtitle {
        font-size: 1.2rem;
    }

    .flyer_flyer-offer h2,
    .flyer_flyer-discount {
        font-size: 1.5rem;
    }

    .flyer_flyer-guarantee,
    .flyer_flyer-guarantee-text {
        font-size: 1.3rem;
    }

    .flyer_flyer-icon_large {
        font-size: 2rem;
    }

    .flyer_flyer-coupon-text {
        font-size: 1.3rem;
    }

    .flyer_flyer-why-choose-us h2, .flyer_flyer-service-list h2 {
        font-size: 2.5rem;
    }

    .flyer_flyer-why-choose-us p, .flyer_flyer-service-list ul {
        font-size: 1.6rem;
    }

    .flyer_flyer-service-list li {
        font-size: 1.2rem;
    }

    .flyer_flyer-footer {
        font-size: 1rem;
    }
}
