.articles-section {
  background-color: white;
  color: black;
  padding: 50px 20px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  border: 5px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.articles-section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  border-bottom: 2px solid gray;
  padding-bottom: 10px;
  color: black;
}

.articles-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.nav-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  transition: color 0.3s;
  position: absolute;
  color: black;
  top: 50%;
  transform: translateY(-50%);
}

.nav-button.left {
  left: -20px; /* Adjusted position */
}

.nav-button.right {
  right: -20px; /* Adjusted position */
}

.nav-button:disabled {
  cursor: not-allowed;
  color: #ccc;
}

.nav-button:not(:disabled):hover {
  color: #000;
}

.articles-items-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
}

.articles-item {
  flex: 1;
  max-width: 25%;
  min-width: 200px;
  margin: 0 10px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.articles-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.articles-content {
  text-align: left;
}

.articles-content h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: black;
}

.articles-content p {
  font-size: 1rem;
  line-height: 1.4;
  color: gray;
}

.icon {
  font-size: 3rem;
  margin-bottom: 10px;
  color: gray;
}

@media (max-width: 768px) {
  .articles-slider {
    flex-direction: column;
    align-items: center;
  }

  .articles-item {
    max-width: 100%;
    min-width: 100%;
  }

  .nav-button.left, .nav-button.right {
    position: static;
    transform: none;
    margin: 10px 0;
  }
}
