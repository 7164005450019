.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  text-align: center;
  z-index: 1000;
}

.cookie-consent p {
  margin: 0 0 10px;
}

.cookie-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cookie-buttons button {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  border: 2px solid white;
  background: transparent;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
}

.cookie-buttons button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.cookie-buttons button:active {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(0.98);
}
