.about-page {
  background-color: white;
  color: black;
  padding: 50px 20px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  border: 5px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin: 50px auto; /* Center horizontally and add top/bottom margin */
}

.about-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about-section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center; /* Center align the section titles */
  width: 100%;
  border-bottom: 2px solid gray;
  padding-bottom: 10px;
  color: black;
}

.about-section, .mission-section, .origin-section, .why-choose-us {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.about-section h2, .mission-section h2, .origin-section h2, .why-choose-us h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: black;
}

.about-section p, .mission-section p, .origin-section p, .why-choose-us p {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #555;
}

.why-choose-us .features-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.feature-item {
  background-color: rgba(0, 0, 0, 0.05);
  color: black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33% - 40px);
  box-sizing: border-box;
  max-width: calc(33% - 40px);
  text-align: center;
}

.feature-item h3 {
  margin-top: 10px;
  font-size: 1.8rem;
  color: black;
}

.feature-item p {
  font-size: 1.2rem;
  color: #777;
}

.cleveland-image-section {
  text-align: center;
}

.cleveland-image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .feature-item {
    flex: 1 1 calc(50% - 40px);
    max-width: calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .feature-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
