.stats {
  background-color: white;
  color: black;
  padding: 50px 20px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  border: 5px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.section-title-stats {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  border-bottom: 2px solid gray;
  padding-bottom: 10px;
  color: black;
}

.stats-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.stat-item {
  background-color: rgba(0, 0, 0, 0.05);
  color: black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33% - 40px);
  box-sizing: border-box;
  max-width: calc(33% - 40px);
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s, transform 0.6s;
}

.stat-item.animate {
  opacity: 1;
  transform: translateY(0);
}

.stat-item h3 {
  margin-top: 10px;
  font-size: 2rem;
  color: black;
}

.stat-item p {
  font-size: 1.2rem;
  color: gray;
}

.right-partners-stats {
  margin-top: 50px;
  padding: 40px 60px; /* Add more padding for space */
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 900px; /* Restrict the width */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.right-partners-stats h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: black;
}

.right-partners-stats p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 30px;
  color: gray;
}

.stats-button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.stats-transparent-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: black;
  border: 2px solid black;
  background: transparent;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px;
  cursor: pointer;
}

.stats-transparent-button span {
  margin-left: 10px;
  transition: margin-left 0.3s;
}

.stats-transparent-button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.stats-transparent-button:hover span {
  margin-left: 15px;
}

@media (max-width: 768px) {
  .stat-item {
    flex: 1 1 calc(50% - 40px);
    max-width: calc(50% - 40px);
  }

  .right-partners-stats {
    padding: 30px 40px; /* Adjust padding for smaller screens */
    max-width: 100%; /* Allow full width on smaller screens */
  }

  .right-partners-stats h2 {
    font-size: 2rem;
  }

  .right-partners-stats p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .stat-item {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .right-partners-stats {
    padding: 20px; /* Adjust padding for mobile */
  }
}
