.App {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, p {
  margin: 0 0 20px;
}

button {
  cursor: pointer;
}

/* Scrollbar styling for WebKit browsers */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #ffffff; /* Background of the track */
}

::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0); /* Black color for the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0); /* Darker black on hover */
}

/* Scrollbar styling for Firefox */
body {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgb(86, 43, 43) #f1f1f1; /* Thumb color and track color */
}

body::-webkit-scrollbar-track {
  background: #000000;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0); /* Black color for the scrollbar thumb */
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}
