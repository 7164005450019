/* Container for the entire post content */
.post-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.8;
  color: #333;
  font-size: var(--article-font-size, 1.125rem); /* Dynamic font size */
}

/* Title of the article */
.post-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #222;
}

/* Date posted and action buttons */
.date-posted-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.date-posted {
  font-size: 0.9rem;
  color: #777;
}

/* Features like Listen, Share, Print, etc. */
.post-features {
  display: flex;
  gap: 10px;
}

/* General button styles */
.transparent-button {
  background: none;
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-size: 0.9rem;
}

.transparent-button:hover {
  background-color: #f0f0f0;
  border-color: #888;
}

/* Close button with the same style */
.close-button {
  display: block;
  margin: 40px auto;
  padding: 10px 20px;
  background: none;
  border: 1px solid #ccc;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  text-align: center;
}

.close-button:hover {
  background-color: #f0f0f0;
  border-color: #888;
}

/* Text size controls */
.text-size {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Paragraphs */
.post-content p {
  margin-bottom: 15px;
  text-align: left;
  line-height: 1.8;
}

/* Headings inside the post */
.post-content h2 {
  font-size: 1.75rem;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #444;
}

/* Responsive images inside the post */
.post-content img {
  max-width: 80%;
  height: auto;
  display: block;
  margin: 20px auto;
}

/* Responsive lists inside the post */
.post-content ul,
.post-content ol {
  margin-left: 20px;
  margin-bottom: 15px;
}

.post-content li {
  margin-bottom: 10px;
}

/* Blockquotes for emphasizing text */
.post-content blockquote {
  margin: 20px auto;
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-left: 5px solid #ccc;
  font-style: italic;
}

/* Hide the listen button and text size controls on mobile */
@media (max-width: 768px) {
  .listen-button,
  .text-size {
    display: none !important;
  }



  .post-content {
    padding: 15px;
  }

  .post-content h1 {
    font-size: 2rem;
  }

  .post-content p {
    font-size: 1rem;
    text-align: left;
  }

  .post-content blockquote {
    padding: 10px 15px;
  }

  .close-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .date-posted-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .post-features {
    justify-content: flex-start;
    margin-top: 10px;
  }
}

/* Very small screens (small phones) */
@media (max-width: 480px) {
  .post-content {
    padding: 10px;
  }

  .post-content h1 {
    font-size: 1.75rem;
  }

  .post-content p {
    font-size: 0.95rem;
    text-align: left;
  }

  .post-content blockquote {
    padding: 8px 12px;
  }

  .close-button {
    padding: 8px 14px;
    font-size: 0.85rem;
  }
}
