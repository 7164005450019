.support-container {
  background-color: white;
  color: black;
  padding: 50px 20px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  border: 5px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 50px auto;
}

.support-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  border-bottom: 2px solid gray;
  padding-bottom: 10px;
  color: black;
  animation: fadeIn 1s ease-in-out;
}

.support-intro {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: black;
  animation: fadeIn 1s ease-in-out;
}

.support-login-button {
  color: #40e0d0; /* Turquoise color */
  text-decoration: none;
  font-weight: bold;
}

.support-login-button:hover {
  color: #30c0b0; /* Darker turquoise on hover */
}

.support-section, .support-features {
  margin-bottom: 40px;
}

.support-subtitle {
  font-size: 2rem;
  margin-bottom: 20px;
  color: black;
}

.support-text {
  font-size: 1rem;
  color: #cccccc;
}

.support-link {
  color: #40e0d0; /* Turquoise color */
  text-decoration: none;
  font-weight: bold;
}

.support-link:hover {
  color: #30c0b0; /* Darker turquoise on hover */
}

.support-features {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  animation: fadeIn 1s ease-in-out;
}

.support-card {
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.support-icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: white;
}

.support-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.support-card p {
  font-size: 1rem;
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
