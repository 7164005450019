.service-industries-page {
    font-family: 'Courier New', Courier, monospace;
    color: #ffffff; /* White */
    background-color: #000; /* Black */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.service-industries-hero {
    text-align: center;
    padding: 50px 20px;
    background: linear-gradient(135deg, #111, #333); /* Dark gray gradient */
    box-shadow: 0 0 30px #808080; /* Gray glow */
    border-radius: 15px;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 40px;
}

.service-industries-hero h1 {
    font-size: 4rem;
    margin-bottom: 20px;
    color: #ffffff; /* White */
    text-shadow: 0 0 10px #808080; /* Gray glow */
}

.service-industries-hero p {
    font-size: 1.5rem;
    color: #ccc; /* Light gray */
    text-shadow: 0 0 5px #808080; /* Gray glow */
}

.service-industries-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
}

.service-industries-card {
    background: #0f0f0f; /* Dark gray */
    border: 2px solid #808080; /* Gray border */
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 20px #808080; /* Gray glow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-industries-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 40px #40e0d0; /* White glow on hover */
}

.service-industries-icon {
    font-size: 3rem;
    margin-bottom: 15px;
    color: #ffffff; /* White */
    text-shadow: 0 0 10px #808080; /* Gray glow */
}

.service-industries-title {
    font-size: 1.8rem;
    color: #ffffff; /* White */
    margin-bottom: 10px;
    text-shadow: 0 0 5px #808080; /* Gray glow */
}

.service-industries-description {
    font-size: 1.2rem;
    color: #ccc; /* Light gray */
    line-height: 1.6;
    text-shadow: 0 0 5px #808080; /* Gray glow */
}
