.header {
  background-color: white;
  padding: 0 20px;
  border-bottom: 5px solid gray; /* Steampunk-style border */
  position: relative;
  z-index: 10;
  height: 70px; /* Adjusted height for better alignment */
  display: flex;
  align-items: center;
  font-family: 'Courier New', Courier, monospace;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: black;
  text-decoration: none; /* Removes underline */
}

.contact-info {
  display: flex;
  gap: 20px;
  font-weight: bold;
  color: black;
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent white background */
  padding: 10px;
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
  align-items: center;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.info-item i {
  font-size: 16px;
  color: black;
}

.phone-icon::before {
  content: '\260E'; /* Unicode for phone icon */
}

.mail-icon::before {
  content: '\2709'; /* Unicode for mail icon */
}

.clock-icon::before {
  content: '\1F557'; /* Unicode for Clock Face Eight O'Clock */
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: bold;
  color: black;
  transition: transform 0.3s ease-in-out;
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: gray; /* Change to gray on hover */
}

.client-login {
  display: inline-block;
  padding: 10px 20px;
  color: black;
  border: 2px solid black;
  background: transparent;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px; /* Makes the button rounded */
  cursor: pointer;
  align-self: center; /* Center vertically */
}

.client-login span {
  margin-left: 10px;
  transition: margin-left 0.3s;
}

.client-login:hover {
  background: rgba(0, 0, 0, 0.1);
}

.client-login:hover span {
  margin-left: 15px;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 20; /* Ensure it overlays any other content */
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
  transition: 0.4s;
}

.hamburger-menu .bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger-menu .bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.nav-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 70px; /* Adjust this to align properly below the header */
  right: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  z-index: 20; /* Ensure it overlays any other content */
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    align-items: flex-start;
  }

  .hamburger-menu {
    display: flex;
  }

  .nav-links.open {
    display: flex;
  }

  .contact-info {
    display: none;
  }
}
