.Infrastructure-Audit-page {
    font-family: 'Courier New', Courier, monospace;
    color: white;
    background-color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.Infrastructure-Audit-hero {
    height: 80vh;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 128, 128, 0.9));
    color: #ffffff;
    padding: 10px 50px 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.Infrastructure-Audit-hero-content {
    z-index: 1;
    margin-top: 20px;
}

.Infrastructure-Audit-title, 
.Infrastructure-Audit-subtitle, 
.Infrastructure-Audit-cta-button {
    font-family: 'Orbitron', sans-serif;
}

.Infrastructure-Audit-title {
    font-size: 3.5rem;
    margin-bottom: 10px;
    margin-top: 50px;
}

.Infrastructure-Audit-subtitle {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #ddd;
}

.Infrastructure-Audit-cta-button {
    display: inline-block;
    padding: 15px 30px;
    color: white;
    border: 2px solid white;
    background: transparent;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem;
    transition: background 0.3s, color 0.3s;
    border-radius: 25px;
    cursor: pointer;
}

.Infrastructure-Audit-cta-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.Infrastructure-Audit-hero-icon {
    position: absolute;
    top: 60%;
    right: 150px;
    transform: translateY(-50%);
    z-index: 0;
}

.Infrastructure-Audit-server-icon {
    font-size: 8rem;
    color: rgba(255, 255, 255, 0.3);
}

.Infrastructure-Audit-magnifying-glass-icon {
    font-size: 12rem;
    color: rgba(64, 224, 208, 0.5);
    position: absolute;
    top: 35%;
    right: -30%;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.Infrastructure-Audit-down-arrow {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.7);
    animation: bounce 2s infinite;
    z-index: 10;
    display: block;
}

@media (max-width: 768px) {
    .Infrastructure-Audit-hero {
        height: calc(100vh - 180px); /* Reduced height to bring content higher up */
    }

    .Infrastructure-Audit-hero-icon {
        display: none; /* Hide icons on mobile */
    }

    .Infrastructure-Audit-down-arrow {
        bottom: 40px; /* Move down arrow higher to meet the fold */
    }
}

.Infrastructure-Audit-additional-sections {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Infrastructure-Audit-section {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.Infrastructure-Audit-section-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
}

.Infrastructure-Audit-section-title::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: gray;
    margin-top: 10px;
    position: absolute;
    bottom: -10px;
    left: 0;
}

.Infrastructure-Audit-section-icon {
    font-size: 2rem;
    color: turquoise;
}

.Infrastructure-Audit-section-content {
    font-size: 1.8rem;
    line-height: 1.8;
    color: #ddd;
    margin-bottom: 20px;
}

.Infrastructure-Audit-section-content ul {
    list-style: none;
    padding-left: 0;
}

.Infrastructure-Audit-section-content li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
}

.Infrastructure-Audit-section-content li:before {
    content: '•';
    position: absolute;
    left: 0;
    color: turquoise;
    font-size: 1.5rem;
}
