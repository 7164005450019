.login-page {
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to start for closer position to header */
  height: 100vh;
  font-family: 'Courier New', Courier, monospace;
  padding: 20px;
}

.form-container {
  background-color: rgba(10, 11, 12, 0.8);
  padding: 30px 20px; /* Reduced padding for a more compact layout */
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 0 0 20px rgba(63, 79, 72, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin-top: 50px; /* Adjusted margin to bring it closer to the header */
}

.error-text {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 10px; /* Reduced margin for a more compact layout */
  color: #ffffff;
}

.form-group {
  margin-bottom: 15px; /* Reduced margin for a more compact layout */
  padding: 0 10px; /* Add padding for equal spacing */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
}

.form-group input {
  width: calc(100% - 20px); /* Adjust width for equal spacing */
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background-color: rgba(10, 11, 12, 0.8);
  color: #fcfcfc;
}

.transparent-buttonlogin {
  display: inline-block;
  padding: 10px 20px;
  color: #000000;
  border: 2px solid #ffffff;
  background: #ffffff;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px; /* Adjusted spacing */
}

.transparent-buttonlogin span {
  margin-left: 10px;
  transition: margin-left 0.3s;
}

.transparent-buttonlogin:hover {
  background: #7f8282;
}

.transparent-buttonlogin:hover span {
  margin-left: 15px;
}

.demo-link {
  margin-top: 20px;
  color: #ffffff;
  text-decoration: none;
}

.demo-link-text {
  color: #40e0d0; /* Neon turquoise */
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s;
}

.demo-link-text:hover {
  color: #ff4500; /* Change to neon pink on hover */
}
